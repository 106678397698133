export function transformDateStringToWeekdayDayMonthYear(dateString: string): string {
  const date = new Date(dateString);

  return date.toLocaleString("de-DE", {
    day: "numeric",
    month: "long",
    weekday: "long",
    year: "numeric",
  });
}
